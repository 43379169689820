"use client"

import { SessionProvider } from "next-auth/react"
import React from 'react'
import SettingsContextProvider from '@/context/settings-context'
import { IBasket, ISetting } from '@/entities'
import { BasketContextProvider } from '@/context/basket-context'

export default function Provider({
  children, session, settings, initialBasket
}: {
  children: React.ReactNode
  session: any
  settings: ISetting[],
  initialBasket: IBasket
}): React.ReactNode {
  return <SessionProvider session={session}>
    <SettingsContextProvider settings={settings}>
      <BasketContextProvider initialBasket={initialBasket}>
        {children}
      </BasketContextProvider>
    </SettingsContextProvider>
  </SessionProvider>
}

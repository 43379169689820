'use client'

import Image from 'next/image'
import NextLink from 'next/link'
import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useTranslations } from 'next-intl'
import { useRouter, usePathname } from 'next/navigation'
import {
  CallbackModal,
  CallbackSuccessModal,
  LoginModal,
  ForgotPasswordModal,
  OTPPhoneLoginModal,
  RegisterModal,
  WelcomeModal,
  BasketModal,
} from '@/components/modal'
import HeaderSearchForm from '@/components/shared/HeaderSearchForm'
import HeaderLanguageSwitcher from '@/components/shared/HeaderLanguageSwitcher'
import { ESettingKey } from '@/services/settings'
import { useSettingsContext } from '@/context/settings-context'
import styles from './Header.module.scss'
import { useSession } from 'next-auth/react'
import HeaderMobileLanguageSwitcher from '@/components/shared/HeaderMobileLanguageSwitcher'

type TProps = {
  locale: string
}

const Header = ({ locale }: TProps) => {
  const { settings } = useSettingsContext()

  const t = useTranslations()
  const pathname = usePathname()
  const router = useRouter()
  const { status } = useSession()

  const [isBlueBackgroundEnable, setIsBlueBackgroundEnable] = useState<boolean>(true)

  useEffect(() => {
    if (/privacy-policy/.test(pathname) || /checkout/.test(pathname) || /account/.test(pathname)) {
      setIsBlueBackgroundEnable(false)
    } else {
      setIsBlueBackgroundEnable(true)
    }

    setIsMobileMenuShown(false)
  }, [pathname])

  const [isMobileMenuShown, setIsMobileMenuShown] = useState<boolean>(false)
  const [searchFormOpened, setSearchFormOpened] = useState<boolean>(false)
  const [isLoginModalShown, setIsLoginModalShown] = useState(false)
  const [isRegisterModalShown, setIsRegisterModalShown] = useState(false)
  const [isWelcomeModalShown, setIsWelcomeModalShown] = useState(false)
  const [isCallbackModalShown, setIsCallbackModalShown] = useState(false)
  const [isCallbackSuccessModalShown, setIsCallbackSuccessModalShown] = useState(false)
  const [isOTPLoginModalShown, setIsOTPLoginModalShown] = useState(false)
  const [isForgotPasswordShown, setIsForgotPasswordShown] = useState(false)
  const [isBasketShown, setIsBasketShown] = useState<boolean>(false)

  const handleOpenRegisterModalAction = () => {
    setIsLoginModalShown(false)
    setIsRegisterModalShown(true)
  }

  const handleOpenLoginModalAction = () => {
    setIsForgotPasswordShown(false)
    setIsOTPLoginModalShown(false)
    setIsRegisterModalShown(false)
    setIsLoginModalShown(true)
  }

  const handleOpenWelcomeModalAction = () => {
    setIsRegisterModalShown(false)
    setIsWelcomeModalShown(true)
  }

  const handleOpenCallbackSuccessModalAction = () => {
    setIsCallbackModalShown(false)
    setIsCallbackSuccessModalShown(true)
  }

  const handleOpenOTPLoginModalAction = () => {
    setIsLoginModalShown(false)
    setIsOTPLoginModalShown(true)
  }

  const handleForgotPasswordModalAction = () => {
    setIsLoginModalShown(false)
    setIsForgotPasswordShown(true)
  }

  const phone = settings.find(x => x.key === ESettingKey.PHONE)?.value as string
  const email = settings.find(x => x.key === ESettingKey.EMAIL)?.value as string

  const handleLogInAction = () => {
    if (status === 'authenticated') {
      router.push('/account')
    } else {
      setIsLoginModalShown(true)
    }
  }

  return (
    <>
      <header className={classNames('header', { 'blur-bg': isBlueBackgroundEnable && !isMobileMenuShown })}>
        <div className='container-fluid'>
          <div className='row header-element-list row-cols-auto align-items-center'>

            <div className='logo-wrapper col'>
              <NextLink href='/'>
                <Image
                  src={'/static/images/logo.svg'}
                  alt='Palche logo'
                  width={100}
                  height={23}
                />
              </NextLink>
            </div>

            <div className='wrapper-navbar col'>
              <nav className='navbar navbar-expand-xl navbar-light bg-faded justify-content-start'>

                <button
                  className={classNames('navbar-toggler menu-button', {
                    'collapsed': !isMobileMenuShown,
                    'open': isMobileMenuShown,
                  })}
                  type='button'
                  onClick={() => setIsMobileMenuShown(!isMobileMenuShown)}
                  aria-label='Toggle navigation'
                >
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </button>

                <div
                  className={classNames('collapse navbar-collapse', { 'show': isMobileMenuShown })}
                  id='navbar'
                >
                  <div className='wrap-navbar'>

                    <ul className='navbar-nav mr-auto'>
                      <li className='nav-item'>
                        <NextLink
                          className='nav-link'
                          href='/catalog'
                        >
                          {t('menu.catalog')}
                        </NextLink>
                      </li>
                      <li className='nav-item'>
                        <NextLink
                          className='nav-link '
                          href='/videos'
                        >
                          {t('menu.videos')}
                        </NextLink>
                      </li>
                      <li className='nav-item'>
                        <NextLink
                          className='nav-link'
                          href='/dealers'
                        >
                          {t('menu.partners')}
                        </NextLink>
                      </li>
                      <li className='nav-item'>
                        <NextLink
                          className='nav-link'
                          href='/news'
                        >
                          {t('menu.news')}
                        </NextLink>
                      </li>
                      <li className='nav-item'>
                        <NextLink
                          className='nav-link'
                          href='/contacts'
                        >
                          {t('menu.contacts')}
                        </NextLink>
                      </li>
                      <li className='nav-item'>
                        <NextLink
                          className='nav-link'
                          href='/about'
                        >
                          {t('menu.about-us')}
                        </NextLink>
                      </li>
                    </ul>

                    <div className='top-line'>
                      <div className='user-menu visible-for-collapse col'>
                        <ul>
                          <li>
                            <button
                              type='button'
                              className='mobile-login-button'
                              onClick={() => handleLogInAction()}
                            >
                              {t('action.log-in')}
                            </button>
                          </li>
                        </ul>
                      </div>

                      {isMobileMenuShown && (
                        <>
                          <HeaderMobileLanguageSwitcher
                            locale={locale}
                            className='d-block d-lg-none mobile-language-switcher'
                          />

                          <div className='order-call d-block d-lg-none col'>
                            <button type='button' onClick={() => setIsCallbackModalShown(true)}>
                              {t('action.call')}
                            </button>
                          </div>

                          {(!!phone || !!email) && (
                            <div className='phone w-100 flex-column'>
                              {phone && (
                                <div className='d-block pb-2'>
                                  <a href={`tel:${phone}`}>{phone}</a>
                                </div>
                              )}

                              {email && (
                                <div className='d-block w-100'>
                                  <a href={`mailto:${email}`}>{email}</a>
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </nav>
            </div>

            <HeaderLanguageSwitcher locale={locale} />

            <div className='order-call move-elements col'>
              <button
                type='button'
                onClick={() => setIsCallbackModalShown(true)}
              >
                {t('action.call')}
              </button>
            </div>

            {(!!phone || !!email) && (
              <div className='phone move-elements col text-center'>
                {phone && (<a href={`tel:${phone}`} className="d-block">{phone}</a>)}
                {email && (<a href={`mailto:${email}`} className="d-block">{email}</a>)}
              </div>
            )}

            <div className='user-menu col'>
              <ul>
                <li className='hide-for-collapse'>
                  <button
                    type='button'
                    className='btn btn-link m-0 p-0'
                    onClick={() => handleLogInAction()}
                  >
                    <Image
                      src='/static/images/icons/user-login.svg'
                      alt='user-login'
                      width={24}
                      height={23}
                    />
                  </button>
                </li>
                <li>
                  <button
                    type='button'
                    className={classNames(
                      'btn btn-link m-0 p-0 open-search',
                      { 'open': searchFormOpened },
                    )}
                  >
                    <Image
                      className='search-icon'
                      src='/static/images/icons/search.svg'
                      alt='search'
                      width={30}
                      height={30}
                      onClick={() => setSearchFormOpened(!searchFormOpened)}
                    />
                  </button>
                </li>
                <li className={styles.header_search}>
                  <button
                    type='button'
                    className='btn btn-link m-0 p-0'
                    onClick={() => setIsBasketShown(true)}
                  >
                    <Image
                      src='/static/images/icons/cart.svg'
                      alt='cart'
                      width={30}
                      height={30}
                    />
                  </button>
                </li>
              </ul>
            </div>

            <HeaderSearchForm
              opened={searchFormOpened}
              close={() => setSearchFormOpened(!searchFormOpened)}
            />

          </div>
        </div>
      </header>

      {isLoginModalShown && (
        <LoginModal
          openRegisterModalAction={handleOpenRegisterModalAction}
          openOTPLoginModalAction={handleOpenOTPLoginModalAction}
          openForgotPasswordModalAction={handleForgotPasswordModalAction}
          close={() => setIsLoginModalShown(false)}
        />
      )}

      {isRegisterModalShown && (
        <RegisterModal
          openLoginModalAction={handleOpenLoginModalAction}
          openWelcomeModalAction={handleOpenWelcomeModalAction}
          close={() => setIsRegisterModalShown(false)}
        />
      )}

      {isWelcomeModalShown && (
        <WelcomeModal
          close={() => setIsWelcomeModalShown(false)}
        />
      )}

      {isCallbackModalShown && (
        <CallbackModal
          complete={handleOpenCallbackSuccessModalAction}
          close={() => setIsCallbackModalShown(false)}
        />
      )}

      {isCallbackSuccessModalShown && (
        <CallbackSuccessModal
          close={() => setIsCallbackSuccessModalShown(false)}
        />
      )}

      {isOTPLoginModalShown && (
        <OTPPhoneLoginModal
          openLoginModalAction={handleOpenLoginModalAction}
          close={() => setIsOTPLoginModalShown(false)}
        />
      )}

      {isForgotPasswordShown && (
        <ForgotPasswordModal
          openLoginModalAction={handleOpenLoginModalAction}
          close={() => setIsForgotPasswordShown(false)}
        />
      )}

      {isBasketShown && (
        <BasketModal
          close={() => setIsBasketShown(false)}
          checkout={() => setIsBasketShown(false)}
        />
      )}
    </>
  )
}

export default Header


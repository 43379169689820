import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.20.0_next@14.2.14_@babel+core@7.25.7_@opentelemetry+api@1.9.0_react-dom@18.3.1_re_yz2utxt2e3x56yhtixu7gquqjq/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.14_@babel+core@7.25.7_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.4/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.14_@babel+core@7.25.7_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.4/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/shared/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FacebookPixelEvents"] */ "/app/src/components/smart/FacebookPixelEvents.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/providers/ClientProvider.tsx");
